import { FileDTO } from './CommonFieldsDTO';
import {
  IFooter,
  IFooterColumnGroupItem,
  IFooterNavigationGroup,
  IFooterNavigationLinkGroupItem,
  IFooterReference,
  IFooterSocialMediaGroupItem,
  IFooterSubMenuItem,
} from '../interfaces/IFooter';
import {
  TFooter,
  TFooterColumnGroupItem,
  TFooterNavigationGroup,
  TFooterNavigationLinkGroupItem,
  TFooterSocialMediaGroupItem,
  TFooterSubMenuItem,
} from '../types/TFooter';

export const FooterReferenceDTO = ({ footer_reference }: IFooterReference): TFooter => FooterDTO(footer_reference[0]);

export const FooterDTO = (footer: IFooter): TFooter => ({
  contentTypeUid: 'footer',
  columnGroup: footer.column_group.map(FooterColumnGroupDTO),
  navigationGroup: FooterNavigationGroupDTO(footer.navigation_group),
  socialMediaGroup: footer.social_media_group.map(FooterSocialMediaGroupItemDTO),
});

export const FooterColumnGroupDTO = (columnGroup: IFooterColumnGroupItem): TFooterColumnGroupItem => ({
  columnTitle: columnGroup.column_title,
  subMenu: columnGroup.sub_menu.map(FooterSubMenuItemDTO),
  uid: columnGroup._metadata.uid,
});

export const FooterSubMenuItemDTO = (subMenuItem: IFooterSubMenuItem): TFooterSubMenuItem => ({
  subMenuTitle: subMenuItem.sub_menu_title,
  subMenuUrl: subMenuItem.sub_menu_url,
  cssClass: subMenuItem.class,
  visibleOnDesktopOnly: subMenuItem.visible_on_desktop_only,
  openInNewWindow: subMenuItem.open_in_new_window,
  uid: subMenuItem._metadata.uid,
});

export const FooterNavigationGroupDTO = (navigationGroup: IFooterNavigationGroup): TFooterNavigationGroup => ({
  copyrightText: navigationGroup.copyright_text,
  linkGroup: navigationGroup.link_group.map(FooterNavigationLinkGroupItemDTO),
});

export const FooterNavigationLinkGroupItemDTO = (
  navigationLinkGroupItem: IFooterNavigationLinkGroupItem,
): TFooterNavigationLinkGroupItem => ({
  linkText: navigationLinkGroupItem.link_text,
  linkUrl: navigationLinkGroupItem.link_url,
  openInNewWindow: navigationLinkGroupItem.open_in_new_window,
  uid: navigationLinkGroupItem._metadata.uid,
});

export const FooterSocialMediaGroupItemDTO = (
  socialMediaGroupItem: IFooterSocialMediaGroupItem,
): TFooterSocialMediaGroupItem => ({
  name: socialMediaGroupItem.name,
  iconImage: FileDTO(socialMediaGroupItem.icon_image),
  socialMediaUrl: socialMediaGroupItem.social_media_url,
  uid: socialMediaGroupItem._metadata.uid,
});
