import className from 'classnames';
import { IYouTubePlayerProps } from './types/IYouTubePlayerProps';
import { MetaDataVideo } from '../../../../SEO/Video';
import styles from '../VideoIframe.module.scss';

const YouTubePlayer = ({
  classNames,
  iframeClassName,
  videoId,
  autoplay = false,
  loop = false,
  controls = true,
  relatedVideos = false,
  seoData,
  isPlaylist = false,
}: IYouTubePlayerProps) => {
  let url = new URL(`https://www.youtube.com/embed/${videoId}`);

  if (isPlaylist) {
    url = new URL(`https://www.youtube.com/embed?listType=playlist&list=${videoId}`);
  }

  if (relatedVideos) {
    url.searchParams.set('rel', '1');
  }

  if (autoplay) {
    url.searchParams.set('mute', '1');
    url.searchParams.set('autoplay', '1');
  }

  if (!controls) {
    url.searchParams.set('controls', '0');
  }

  if (loop) {
    url.searchParams.set('loop', '1');

    if (!isPlaylist) {
      url.searchParams.set('playlist', videoId);
    }
  }

  url.searchParams.set('allowfullscreen', '1');

  return (
    <div data-testid="youtube-player" className={className(styles['video-wrapper'], classNames)}>
      <iframe
        className={className(`${styles['video-iframe']}`, iframeClassName)}
        allow="autoplay"
        src={url.href}
        title={seoData?.name || seoData?.description || 'youtube-video'}
      />
      <MetaDataVideo
        name={seoData?.name || null}
        contentUrl={seoData?.contentUrl || null}
        description={seoData?.description || null}
        duration={seoData?.duration || null}
        embedUrl={seoData?.embedUrl || null}
        thumbnailUrl={seoData?.thumbnailUrl || null}
        uploadDate={seoData?.uploadDate || null}
      />
    </div>
  );
};

export default YouTubePlayer;
