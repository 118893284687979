import React from 'react';
import { GTM_CONTAINER_ID_EN_US } from '../../../helpers/constants/googleTagManager';

export const sendGTMEvent = (data: Object) => {
  // @ts-ignore
  if (window.dataLayer) {
    // @ts-ignore
    window.dataLayer.push(data)
  } else {
    console.warn('GTMSnippet: GTM dataLayer does not exist');
  }
}

const GTMSnippet = () => {
  if (Boolean(GTM_CONTAINER_ID_EN_US)) {
    // @ts-ignore
    return (
      <>
        <script
          type="text/javascript"
          id="gtm-script"
          dangerouslySetInnerHTML={{
            __html: `
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${GTM_CONTAINER_ID_EN_US}');
    `,
          }}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default GTMSnippet;
