import { Product } from '@commercetools/frontend-domain-types/product';

export type TCommonContentBlock = {
  contentTypeUid: string;
  uid: string;
};

export type TFileMandatory = {
  contentType: string;
  fileSize: string;
  filename: string;
  title: string;
  parentUid: string | null;
  url: string;
};

export type TFile = TFileMandatory | null;

export type TIConFile = {
  filePath: TFile | null;
  maxWidth: string;
  altText: string;
  redirectLinkUrl: string;
} | null;

export type TColorValueHex = `#${string}` | '';

export enum ETextAlignments {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum EHorizontalAlignments {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum EVerticalAlignments {
  Top = 'Top',
  Center = 'Center',
  Bottom = 'Bottom',
}

export type TVideoLocations = 'YouTubePlaylist' | 'YouTube' | 'Vimeo' | 'ContentStack' | null;

export type THeadlineColors = 'white' | 'black';

export type TLink = {
  title: string;
  href: string;
};

export enum ETextPosition {
  Above = 'Above',
  Below = 'Below',
}

export type TNavigationMenu = TNavigationMenuItem[];

export type TNavigationMenuItem = {
  menuLink: TLink;
  openInNewTab: boolean;
  hasSubMenu: boolean;
  subMenu: TNavigationSubMenuItem[];
  uid: string;
};

export type TNavigationSubMenuItem = {
  subMenuLink: TLink;
  openInNewTab: boolean;
  displayOnlyMobile: boolean;
  uid: string;
};

export type TCommercetoolsProduct = {
  data: Product[];
  type: string;
};

export type TPageMetaData = {
  metaDescription: string;
  metaImage: TFile;
  metaTitle: string;
  metaKeywords?: string;
};
