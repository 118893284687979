import { CtProductIDTO, FileDTO } from './CommonFieldsDTO';
import {
  IFullWidthImage,
  IFullWidthImageAssociateProduct,
  IFullWidthImageBlock,
  IFullWidthImageGroup,
  IFullWidthImageLinkGroupItem,
  IFullWidthImagePanelBlock,
  IFullWidthImageReference,
} from '../interfaces/IFullWidthImage';
import { EHorizontalAlignments, ETextAlignments, EVerticalAlignments } from '../types/CommonFields';
import {
  TFullWidthImage,
  TFullWidthImageAssociateProduct,
  TFullWidthImageGroup,
  TFullWidthImageLinkGroupItem,
  TFullWidthImagePanel,
} from '../types/TFullWidthImage';

const actualDate = new Date().valueOf();

export const FullWidthImageBlockDTO = ({ full_width_image_block }: IFullWidthImageBlock): TFullWidthImage =>
  FullWidthImageReferenceDTO(full_width_image_block);

export const FullWidthImageReferenceDTO = ({ full_width_image_reference }: IFullWidthImageReference): TFullWidthImage =>
  FullWidthImageDTO(full_width_image_reference[0]);

export const FullWidthImageDTO = (fullWidthImage: IFullWidthImage): TFullWidthImage => {
  const expirationDate = fullWidthImage.expiration_row ? new Date(fullWidthImage.expiration_row) : null;

  return {
    contentTypeUid: 'full_width_image',
    expirationRow: expirationDate,
    isContentExpired: expirationDate !== null && expirationDate.valueOf() - actualDate < 0,
    imagePanelBlock: fullWidthImage.image_panel_block?.map(FullWidthImagePanelDTO),
    uid: fullWidthImage.uid,
  };
};

const FullWidthImagePanelDTO = ({ image_panel: imagePanel }: IFullWidthImagePanelBlock): TFullWidthImagePanel => {
  const expirationDate = imagePanel.image_panel_expiration ? new Date(imagePanel.image_panel_expiration) : null;

  return {
    imagePanelExpiration: expirationDate,
    isExpired: expirationDate !== null && expirationDate.valueOf() - actualDate < 0,
    associateProduct: AssociateProductDTO(imagePanel.associate_product),
    imageGroup: ImageGroupDTO(imagePanel.image_group),
    nameImage: FileDTO(imagePanel.name_image),
    nameImageAlt: imagePanel.name_image_alt,
    headline: imagePanel.headline,
    isH1: imagePanel.is_h1,
    description: imagePanel.description,
    textPlacement: imagePanel.text_placement || ETextAlignments.Center,
    linkGroup: imagePanel.link_group.map(LinkGroupItemDTO),
    imageInfoLinkText: imagePanel.image_info_link_text,
    imageInfoLinkUrl: imagePanel.image_info_link_url,
    openInNewWindow: imagePanel.open_in_new_window,
    showBackgroundOverlay: imagePanel.show_background_overlay,
    uid: imagePanel._metadata.uid,
  };
};

const AssociateProductDTO = (associateProduct: IFullWidthImageAssociateProduct): TFullWidthImageAssociateProduct => ({
  commercetoolsProduct: associateProduct.commercetools_product
    ? CtProductIDTO(associateProduct.commercetools_product)
    : undefined,
  product: associateProduct.product,
  showPriceInformation: associateProduct.show_price_information,
});

const ImageGroupDTO = (imageGroup: IFullWidthImageGroup): TFullWidthImageGroup => ({
  imagePathDesktop: FileDTO(imageGroup.image_path_desktop),
  verticalImagePosition: imageGroup.vertical_image_position || EVerticalAlignments.Center,
  horizontalImagePosition: imageGroup.horizontal_image_position || EHorizontalAlignments.Center,
  imagePathMobile: FileDTO(imageGroup.image_path_position),
  imageAltText: imageGroup.image_alt_text,
  couscousTex: imageGroup.couscous_tex,
});

const LinkGroupItemDTO = (linkGroupItem: IFullWidthImageLinkGroupItem): TFullWidthImageLinkGroupItem => ({
  redirectLinkText: linkGroupItem.redirect_link_text,
  redirectLinkUrl: linkGroupItem.redirect_link_url,
  openNewWindow: linkGroupItem.open_new_window,
  uid: linkGroupItem._metadata.uid,
});
