import { IImagePanelProps } from './types/IImagePanelProps';
import { TEXT_ALIGNMENTS } from '../../../../../helpers/constants/CommonFieldsConstants';
import useProductByKey from '../../../../../helpers/hooks/useProductByKey';
import { formatPrice } from '../../../../../helpers/utils/formatPrice';
import { EHorizontalAlignments, ETextAlignments, EVerticalAlignments } from '../../../../../types/CommonFields';
import ResponsiveImage from '../../../atoms/ResponsiveImage';
import styles from '../FullWidthImage.module.scss';

const ImagePanel = ({ imagePanel, isMobile, isDesktop }: IImagePanelProps) => {
  const verticalImagePosition = isMobile ? EVerticalAlignments.Center : imagePanel.imageGroup.verticalImagePosition;
  const horizontalImagePosition = isMobile
    ? EHorizontalAlignments.Center
    : imagePanel.imageGroup.horizontalImagePosition;
  const textContentAlignment = !isDesktop ? ETextAlignments.Center : imagePanel.textPlacement;

  const productVariant = useProductByKey(imagePanel.associateProduct.product || '')?.variants?.[0];

  return (
    <div className={`${styles['content-wrapper']}`} data-testid={'content-wrapper'}>
      <ResponsiveImage
        tabletImage={imagePanel.imageGroup.imagePathMobile}
        desktopImage={imagePanel.imageGroup.imagePathDesktop}
        altText={imagePanel.imageGroup.imageAltText}
        verticalPosition={verticalImagePosition}
        horizontalPosition={horizontalImagePosition}
        customClass={styles['background']}
      />

      <div className={`container ${styles['text-container']} ${styles[TEXT_ALIGNMENTS[textContentAlignment]]}`}>
        <div
          className={` ${styles['text-content']} ${styles[TEXT_ALIGNMENTS[textContentAlignment]]} ${
            imagePanel.showBackgroundOverlay ? styles['bg-overlay'] : ''
          }`}
        >
          {imagePanel.nameImage?.url && (
            <img
              loading="lazy"
              className={`${styles['name-image']}`}
              alt={imagePanel.nameImageAlt}
              src={imagePanel.nameImage?.url}
              data-testid={'name-image'}
            />
          )}

          {imagePanel.headline &&
            (imagePanel.isH1 ? (
              <h1
                className={styles.title}
                data-testid={'h1-headline'}
                dangerouslySetInnerHTML={{ __html: imagePanel.headline }}
              ></h1>
            ) : (
              <h2
                className={styles.title}
                data-testid={'h2-headline'}
                dangerouslySetInnerHTML={{ __html: imagePanel.headline }}
              ></h2>
            ))}

          {imagePanel.description && (
            <div className={styles.description} data-testid={'description'}>
              {imagePanel.description}
            </div>
          )}

          {productVariant?.price && imagePanel.associateProduct.showPriceInformation && (
            <div className={styles['product-info']} data-testid={'product-info'}>
              <div className={styles['price']}>
                {productVariant.discountedPrice ? (
                  <span className={`${styles.price} ${styles.discounted}`} data-testid="discounted-price">
                    <s>{formatPrice(productVariant.price)}</s>&nbsp;
                    {formatPrice(productVariant.discountedPrice)}
                  </span>
                ) : (
                  <span data-testid="regular-price">{formatPrice(productVariant.price)}</span>
                )}
              </div>

              {productVariant.discounts?.[0] && (
                <div className={styles['discount-info']} data-testid="promotion-info">
                  {productVariant.discounts[0]}
                </div>
              )}
            </div>
          )}

          {Boolean(imagePanel.linkGroup.length) && (
            <ul className={styles['link-buttons']} data-testid={'link-buttons'}>
              {imagePanel.linkGroup.map((linkGroupItem) => {
                return (
                  <li className={styles['link-buttons-item']} key={linkGroupItem.uid} data-testid={'link-buttons-item'}>
                    <a
                      href={linkGroupItem.redirectLinkUrl}
                      target={linkGroupItem.openNewWindow ? '_blank' : '_self'}
                      rel={linkGroupItem.openNewWindow ? 'noreferrer' : undefined}
                      className="nk-btn-rounded nk-btn-primary"
                      data-testid={`button-row-button-${linkGroupItem.uid}`}
                    >
                      {linkGroupItem.redirectLinkText}
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>

      {imagePanel.imageInfoLinkText &&
        (imagePanel.imageInfoLinkUrl ? (
          <a
            className={styles['img-info-text']}
            href={imagePanel.imageInfoLinkUrl}
            target={imagePanel.openInNewWindow ? '_blank' : '_self'}
            rel={imagePanel.openInNewWindow ? 'noreferrer' : undefined}
            data-testid={'info-link-anchor'}
          >
            {imagePanel.imageInfoLinkText}
          </a>
        ) : (
          <span className={styles['img-info-text']} data-testid={'info-link-text'}>
            {imagePanel.imageInfoLinkText}
          </span>
        ))}
    </div>
  );
};

export default ImagePanel;
