import classNames from 'classnames/bind';
import { Container } from 'react-bootstrap';
import { ImageHelpers } from 'helpers/imageHelpers';
import styles from './MarketingHeroHeadline.module.scss';
import { IMarketingHeroHeadlineProps } from './types/IMarketingHeroHeadlineProps';
import { ETextAlignments } from '../../../../../types/CommonFields';

const cx = classNames.bind(styles);

const justificationClasses = {
  Left: 'translate-middle-y start-0 top-50',
  Center: 'translate-middle start-50 top-50',
  Right: 'translate-middle-y end-0 top-50',
};

const MarketingHeroHeadline = ({
  nameImage,
  nameImageAlt,
  headline,
  headlineColor,
  textJustification,
  isBackgroundOverlay,
}: IMarketingHeroHeadlineProps) => {
  if (!headline && !nameImage) {
    return false as unknown as JSX.Element;
  }

  const textPlacement = justificationClasses[textJustification];

  return (
    <div className={styles.container} data-testid="marketing-hero-headline">
      <Container className={'position-relative'}>
        <div
          className={cx(`${styles.headline} ${styles[headlineColor]} ${textPlacement}`, {
            bgOverlay: isBackgroundOverlay,
            'text-center': textJustification === ETextAlignments.Center,
          })}
          data-testid="marketing-hero-headline-text"
        >
          {nameImage && (
            <img
              src={ImageHelpers.modifiedContentStackImageUrl(nameImage.url, '280')}
              alt={nameImageAlt}
              data-testid="headline-name-image"
            />
          )}
          {headline && <h2 dangerouslySetInnerHTML={{ __html: headline }} data-testid="headline-text" />}
        </div>
      </Container>
    </div>
  );
};

export default MarketingHeroHeadline;
