import { FileDTO } from './CommonFieldsDTO';
import {
  ITwoColumnRow,
  ITwoColumnRowColumnGroup,
  ITwoColumnRowGroup,
  ITwoColumnRowLinkGroup,
  ITwoColumnRowReference,
  ITwoColumnRowBlock,
} from '../interfaces/ITwoColumnRow';
import { EHorizontalAlignments, EVerticalAlignments } from '../types/CommonFields';
import {
  TTwoColumnRow,
  TTwoColumnRowColumnGroup,
  TTwoColumnRowGroup,
  TTwoColumnRowLinkGroup,
} from '../types/TTwoColumnRow';

export const TwoColumnRowBlockDTO = ({ two_columns_row_block }: ITwoColumnRowBlock): TTwoColumnRow => {
  return TwoColumnRowReferenceDTO(two_columns_row_block);
};

export const TwoColumnRowReferenceDTO = ({ two_columns_row_reference }: ITwoColumnRowReference): TTwoColumnRow => {
  return TwoColumnRowDTO(two_columns_row_reference[0]);
};

export const TwoColumnRowDTO = (twoColumnRow: ITwoColumnRow): TTwoColumnRow => ({
  contentTypeUid: 'two_columns_row',
  expirationRow: twoColumnRow?.expiration_row ? new Date(twoColumnRow.expiration_row) : null,
  columnGroup: twoColumnRow?.column_group?.map(columnGroupDTO),
  uid: twoColumnRow?.uid,
});

const columnGroupDTO = (columnGroup: ITwoColumnRowColumnGroup): TTwoColumnRowColumnGroup => ({
  imageGroup: imageGroupDTO(columnGroup.image_group),
  nameImage: FileDTO(columnGroup.name_image),
  nameImageAlt: columnGroup.name_image_alt,
  headline: columnGroup.headline,
  description: columnGroup.description,
  openInNewWindow: columnGroup.open_in_new_window,
  textLinksGroup: columnGroup.text_links_group.map(textLinkGroupDTO),
  uid: columnGroup._metadata.uid,
});

const imageGroupDTO = (imageGroup: ITwoColumnRowGroup): TTwoColumnRowGroup => ({
  imagePath: FileDTO(imageGroup.image_path),
  imageAlt: imageGroup.image_alt,
  verticalImagePosition: imageGroup.vertical_image_position || EVerticalAlignments.Center,
  horizontalImagePosition: imageGroup.horizontal_image_position || EHorizontalAlignments.Center,
});

const textLinkGroupDTO = (textLinksGroup: ITwoColumnRowLinkGroup): TTwoColumnRowLinkGroup => ({
  linkText: textLinksGroup.link_text,
  linkUrl: textLinksGroup.link_url,
  openInNewWindow: textLinksGroup.open_in_new_window,
  uid: textLinksGroup._metadata.uid,
});
