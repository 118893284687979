import { ButtonRowBlockDTO } from '../../DTO/ButtonRowDTO';
import { CarouselBlockDTO } from '../../DTO/CarouselDTO';
import { DefinitionsBlockDTO } from '../../DTO/DefinitionsDTO';
import { DisclaimersBlockDTO } from '../../DTO/DisclaimersDTO';
import { FAQsBlockDTO } from '../../DTO/FAQPanelDTO';
import { FeatureBandsBlockDTO } from '../../DTO/FeatureBandDTO';
import { FootNoteBlockDTO } from '../../DTO/FootNoteDTO';
import { FreeEntryBlockDTO } from '../../DTO/FreeEntryDTO';
import { FullWidthImageBlockDTO } from '../../DTO/FullWidthImageDTO';
import { FullWidthTextBlockDTO } from '../../DTO/FullWidthTextDTO';
import { FullWidthVideoBlockDTO } from '../../DTO/FullWidthVideoDTO';
import { HotspotsBlockDTO } from '../../DTO/HotspotsDTO';
import { LandingSubNavBlockDTO } from '../../DTO/LandingSubNavDTO';
import { MarketingHeroBlockDTO } from '../../DTO/MarketingHeroDTO';
import { NavigationCarouselBlockDTO } from '../../DTO/NavigationCarouselDTO';
import { OneToFourColumnBlockDTO } from '../../DTO/OneToFourColumnDTO';
import { OneVideoBlockDTO } from '../../DTO/OneVideoDTO';
import { PlainFeaturesBlockDTO } from '../../DTO/PlainFeaturesDTO';
import { ProductViewExplorerBlockDTO } from '../../DTO/ProductViewExplorerDTO';
import { QuickLinksBlockDTO } from '../../DTO/QuickLinksDTO';
import { TwoColumnRowBlockDTO } from '../../DTO/TwoColumnRowDTO';
import { Z8AccordionBlockDTO } from '../../DTO/Z8AccordionDTO';
import { InlineSliderBlockDTO } from 'DTO/InlineSliderBlockDTO';

interface IContentBlockDTOReferences<T> {
  [key: string]: T;
}

export const CONTENT_BLOCKS_DTO_REFERENCES: IContentBlockDTOReferences<any> = {
  // above_product_list_block: () => ({}),
  button_row_block: ButtonRowBlockDTO,
  definitions_block: DefinitionsBlockDTO,
  disclaimers_block: DisclaimersBlockDTO,
  faq_block: FAQsBlockDTO,
  feature_band_block: FeatureBandsBlockDTO,
  free_entry_block: FreeEntryBlockDTO,
  full_width_image_block: FullWidthImageBlockDTO,
  full_width_text_block: FullWidthTextBlockDTO,
  full_width_video_block: FullWidthVideoBlockDTO,
  hotspots_block: HotspotsBlockDTO,
  carousel_block: CarouselBlockDTO,
  in_line_slider_block: InlineSliderBlockDTO,
  landing_sub_nav_block: LandingSubNavBlockDTO,
  marketing_hero_block: MarketingHeroBlockDTO,
  navigation_carousel_block: NavigationCarouselBlockDTO,
  one_to_four_columns_block: OneToFourColumnBlockDTO,
  one_video_block: OneVideoBlockDTO,
  plain_features_block: PlainFeaturesBlockDTO,
  product_view_explorer_block: ProductViewExplorerBlockDTO,
  quick_links_block: QuickLinksBlockDTO,
  two_columns_row_block: TwoColumnRowBlockDTO,
  z8_accordion_block: Z8AccordionBlockDTO,
  footnote_block: FootNoteBlockDTO,
};
