import { TInlineSlider } from 'types/TInlineSlider';
import ButtonRow from './ButtonRow';
import Definitions from './Definitions';
import DisclaimersBlock from './DisclaimersBlock';
import FAQPanel from './FAQPanel';
import FeatureBand from './FeatureBand';
import FootNotes from './FootNotes';
import FreeEntry from './FreeEntry';
import FullWidthImage from './FullWidthImage';
import FullWidthText from './FullWidthText';
import FullWidthVideo from './FullWidthVideo';
import Hotspots from './Hotspots';
import ImageCarousel from './ImageCarousel';
import InlineSlider from './InlineSlider/InlineSlider';
import LandingSubNav from './LandingSubNav';
import MarketingHero from './MarketingHero';
import NavigationCarousel from './NavigationCarousel';
import OneToFourColumn from './OneToFourColumn';
import { OneVideo } from './OneVideo';
import PlainFeatures from './PlainFeatures';
import ProductViewExplorer from './ProductViewExplorer/ProductViewExplorer';
import QuickLinks from './QuickLinks';
import TwoColumnRow from './TwoColumnImage';
import { IContentBlocksProps } from './Types/IContentBlocksProps';
import Z8Accordion from './Z8Accordion';
import { EContentBlocksUid } from '../../../helpers/constants/EContentBlocksUid';
import { IZ8Accordion } from '../../../interfaces/IZ8Accordion';
import { TButtonRow } from '../../../types/TButtonRow';
import { TCarousel } from '../../../types/TCarousel';
import { TDefinition } from '../../../types/TDefinition';
import { TDisclaimers } from '../../../types/TDisclaimers';
import { TFAQs } from '../../../types/TFAQPanel';
import { TFeatureBand } from '../../../types/TFeatureBand';
import { TFootNotes } from '../../../types/TFootNotes';
import { TFreeEntry } from '../../../types/TFreeEntry';
import { TFullWidthImage } from '../../../types/TFullWidthImage';
import { TFullWidthText } from '../../../types/TFullWidthText';
import { TFullWidthVideo } from '../../../types/TFullWidthVideo';
import { THotspots } from '../../../types/THotspots';
import { TLandingSubNav } from '../../../types/TLandingSubNav';
import { TMarketingHero } from '../../../types/TMarketingHero';
import { TNavigationCarousel } from '../../../types/TNavigationCarousel';
import { TOneToFourColumn } from '../../../types/TOneToFourColumn';
import { TOneVideo } from '../../../types/TOneVideo';
import { TPlainFeatures } from '../../../types/TPlainFeatures';
import { TQuickLinks } from '../../../types/TQuickLinks';
import { TTwoColumnRow } from '../../../types/TTwoColumnRow';

const ContentBlocks = ({ data, product }: IContentBlocksProps) => {
  if (!Boolean(data.length)) {
    return false as unknown as JSX.Element;
  }

  return (
    <div data-testid="content-blocks" className="content-blocks">
      {data.map((block, index) => {
        const key = `${block?.uid}-${index}`;

        switch (block?.contentTypeUid) {
          case EContentBlocksUid.ABOVE_PRODUCT_LIST:
            return (
              <div className="content-block container" key={key}>
                <h1>TODO: @unassigned</h1>
                <strong>NOTE: dont forget to add useAnimation hook</strong>
                <p>
                  Above Product List Component:{' '}
                  <a href="https://jira.nikon.net/browse/REPLATFORM-252">REPLATFORM-252</a>
                </p>
              </div>
            );

          case EContentBlocksUid.BUTTON_ROW:
            return <ButtonRow data={block as unknown as TButtonRow} key={key} />;

          case EContentBlocksUid.DEFINITIONS:
            return <Definitions data={block as unknown as TDefinition} key={key} />;

          case EContentBlocksUid.DISCLAIMERS:
            return <DisclaimersBlock disclaimer={block as unknown as TDisclaimers} key={key} />;

          case EContentBlocksUid.FAQ:
            return <FAQPanel faqs={block as unknown as TFAQs} key={key} />;

          case EContentBlocksUid.FEATURE_BAND:
            return <FeatureBand data={block as unknown as TFeatureBand} key={key} />;

          case EContentBlocksUid.FREE_ENTRY:
            return <FreeEntry data={block as unknown as TFreeEntry} key={key} />;

          case EContentBlocksUid.FULL_WIDTH_IMAGE:
            return <FullWidthImage data={block as unknown as TFullWidthImage} key={key} />;

          case EContentBlocksUid.FULL_WIDTH_TEXT:
            return <FullWidthText data={block as unknown as TFullWidthText} key={key} />;

          case EContentBlocksUid.FULL_WIDTH_VIDEO:
            return <FullWidthVideo data={block as unknown as TFullWidthVideo} key={key} />;

          case EContentBlocksUid.HOTSPOTS:
            return <Hotspots data={block as unknown as THotspots} key={key} />;

          case EContentBlocksUid.IMAGE_CAROUSEL:
            return <ImageCarousel data={block as unknown as TCarousel} key={key} />;

          case EContentBlocksUid.INLINE_SLIDER:
            return <InlineSlider data={block as unknown as TInlineSlider} key={key} />;

          case EContentBlocksUid.LANDING_SUB_NAV:
            return <LandingSubNav data={block as unknown as TLandingSubNav} key={key} />;

          case EContentBlocksUid.MARKETING_HERO:
            return <MarketingHero data={block as unknown as TMarketingHero} key={key} />;

          case EContentBlocksUid.NAVIGATION_CAROUSEL:
            return <NavigationCarousel data={block as unknown as TNavigationCarousel} key={key} />;

          case EContentBlocksUid.ONE_TO_FOUR_COLUMNS:
            return <OneToFourColumn data={block as unknown as TOneToFourColumn} key={key} />;

          case EContentBlocksUid.ONE_VIDEO:
            return <OneVideo {...(block as TOneVideo)} key={key} />;

          case EContentBlocksUid.PLAIN_FEATURES:
            return <PlainFeatures plainFeatures={block as unknown as TPlainFeatures} key={key} />;

          case EContentBlocksUid.PRODUCT_VIEW_EXPLORER:
            return <ProductViewExplorer data={block} product={product} key={key} />;

          case EContentBlocksUid.QUICK_LINKS:
            return <QuickLinks {...(block as TQuickLinks)} key={key} />;

          case EContentBlocksUid.TWO_COLUMNS_ROW:
            return <TwoColumnRow data={block as unknown as TTwoColumnRow} key={key} />;

          case EContentBlocksUid.Z8_ACCORDION:
            return <Z8Accordion data={block as IZ8Accordion} key={key} />;

          case EContentBlocksUid.FOOT_NOTES:
            return <FootNotes data={block as unknown as TFootNotes} />;

          default:
            return null;
        }
      })}
    </div>
  );
};

export default ContentBlocks;
