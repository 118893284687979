import { Money } from '@commercetools/frontend-domain-types/product/Money';
import { TNikonMoney } from '../../types/TNikonMoney';

export const formatPrice = (productPrice: TNikonMoney | Money) => {
  if (!productPrice || !productPrice.centAmount) {
    return null;
  }

  const { centAmount, fractionDigits } = productPrice;
  const number = fractionDigits ? centAmount / 10 ** fractionDigits : centAmount;
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = '$1,';
  const arr = number.toString().split('.');

  arr[0] = arr[0].replace(exp, rep);

  return arr[1] ? `$${arr.join('.')}` : `$${arr[0]}`;
};
