import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { ErrorPageDTO } from 'DTO/ErrorPageDTO';
import { useFormat } from 'helpers/hooks/useFormat';
import { IErrorPage } from 'interfaces/IErrorPage';
import { sdk } from 'sdk';
import { TErrorPage } from 'types/TErrorPage';
import { IErrorPageProps } from './types/IErrorPageProps';
import ContentBlocks from '../ContentBlocks';
import Footer from '../Footer';
import Header from '../Header';
import PromoBanner from '../PromoBanner';

const ErrorPage = ({ data, showPageLayout = true }: IErrorPageProps) => {
  const { formatMessage } = useFormat({ name: 'error' });
  const [errorPageData, setErrorPageData] = useState<TErrorPage | undefined>(undefined);

  const setErrorData = async () => {
    try {
      const response = await sdk.callAction<IErrorPage[]>({
        actionName: 'contentstack/getContentList',
        query: { contentTypeUid: 'error_page_parts', limit: 20 },
      });

      const data = !response.isError && (response.data?.[0]?.data as IErrorPage);

      if (response.isError) throw new Error(response.error.message);
      if (!data) throw new Error('Error page data not found');

      setErrorPageData(ErrorPageDTO(data));
    } catch (error) {
      console.error('Error fetching error page data: ', error);
    }
  };

  useEffect(() => {
    setErrorData();
  }, []);

  const promoBanner = showPageLayout ? data?.promoBanner || errorPageData?.promoBanner : undefined;
  const header = showPageLayout ? data?.header || errorPageData?.header : undefined;
  const footer = showPageLayout ? data?.footer || errorPageData?.footer : undefined;
  const contentBlocks = data?.contentBlocks || errorPageData?.contentBlocks;

  return (
    <div data-testid="error_page">
      <Head>
        <title>{formatMessage({ id: 'error.page.title', defaultMessage: 'Error | Nikon' })}</title>
      </Head>
      {promoBanner && <PromoBanner data={promoBanner} />}
      {header && <Header data={header} />}
      <main className="error-page-main-content" data-testid="static-content-page-main-content">
        {contentBlocks && <ContentBlocks data={contentBlocks} />}
      </main>
      {footer && <Footer data={footer} />}
    </div>
  );
};

export default ErrorPage;
