import React from 'react';
import Image from 'next/image';
import classNames from 'classnames/bind';
import { Nav } from 'react-bootstrap';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import { IUserBarBlockProps } from './types/IUserBlockProps';
import styles from './UserBarBlock.module.scss';
import AccountFlyout from '../AccountFlyout';
import CartFlyout from '../CartFlyout';

const cx = classNames.bind(styles);

const UserBarBlock = ({ data, isDesktopIconNav, locale }: IUserBarBlockProps) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const userBlockDefaultMessages: { [key: string]: string } = {
    account: formatAccountMessage({ id: 'my.account', defaultMessage: 'My Account' }),
    cart: formatCartMessage({ id: 'myCart', defaultMessage: 'My Cart' }),
  };

  const { isEmpty, totalItems, data: cartData } = useCart();

  return (
    <Nav
      className={cx('justify-content-end navbar-nav', {
        'd-flex d-lg-none flex-row': !isDesktopIconNav,
        'd-none d-lg-flex': isDesktopIconNav,
        desktopIconNav: isDesktopIconNav,
      })}
      data-testid="user-bar-block"
    >
      {data.map(({ iconPath, redirectLink, uid }) => (
        <div
          key={`flyout-trigger-${uid}`}
          className={cx({ headerFlyoutTrigger: isDesktopIconNav })}
          data-testid={`flyout-trigger-${uid}`}
        >
          <Nav.Link href={redirectLink.href} className={cx({ 'icon-link': isDesktopIconNav })}>
            {iconPath && (
              <Image
                loader={() => iconPath.url}
                src={iconPath.url}
                width={35}
                height={35}
                alt={redirectLink.title || userBlockDefaultMessages[redirectLink.href.split('/')[1]]}
                unoptimized={true}
                data-testid={`icon-${uid}`}
                className={cx({ 'icon-img': isDesktopIconNav })}
              />
            )}
          </Nav.Link>
          {redirectLink.title.toLowerCase().includes('account') && isDesktopIconNav && (
            <AccountFlyout redirectLink={redirectLink.href} />
          )}
          {redirectLink.title.toLowerCase().includes('cart') && (
            <>
              {!isEmpty && <span className={styles['cart-item-count']}>{totalItems}</span>}
              {isDesktopIconNav && (
                <CartFlyout
                  isEmpty={isEmpty}
                  totalItems={totalItems}
                  data={cartData}
                  locale={locale}
                  cartRedirectLink={redirectLink.href}
                  data-testid="cart-flyout"
                />
              )}
            </>
          )}
        </div>
      ))}
    </Nav>
  );
};

export default UserBarBlock;
