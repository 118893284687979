import { CONTENT_BLOCKS_DTO_REFERENCES } from '../helpers/constants/ContentBlocksDTOReferences';
import { IContentBlocks } from '../interfaces/IContentBlocks';

export const ContentBlocksDTO = (blocks: IContentBlocks) => {
  return blocks.map((block) => {
    const blockName = Object.keys(block)[0];
    const dto = CONTENT_BLOCKS_DTO_REFERENCES[blockName];

    if (!dto) {
      console.error('The following content block is not covered --> ', blockName);
    }

    return dto && dto(block);
  });
};
