import { useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import { Form, Navbar } from 'react-bootstrap';
import SearchIcon from 'components/icons/search';
import styles from './Header.module.scss';
import NavigationMenu from './NavigationMenu';
import { IHeaderProps } from './types/IHeaderProps';
import UserBarBlock from './UserBarBlock';
import { appLocales } from '../../../helpers/constants/appLocales';
import { useFormat } from '../../../helpers/hooks/useFormat';
import useMediaQuery from '../../../helpers/hooks/useMediaQuery';
import { desktop } from '../../../helpers/utils/screensizes';
import { getLocalizationInfo } from '../../../project.config';

const cx = classNames.bind(styles);

const Header = ({ data }: IHeaderProps) => {
  const router = useRouter();
  const locale = getLocalizationInfo(router.locale).locale.split('@')[0];
  const searchBoxRef = useRef<HTMLInputElement | null>(null);
  const [isBiggerThanDesktop] = useMediaQuery(desktop);
  const isMobileDevice = !isBiggerThanDesktop;

  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

  const [showCloseMenuIcon, setShowCloseMenuIcon] = useState(false);
  const { aboveSearch, belowSearch, logoLink, logoPath, userBarBlock, navigationMenu } = data;
  const isShowAboveSearch = aboveSearch.region || aboveSearch.country || aboveSearch.continent;

  const handleOnToggleMenuIcon = () => {
    setShowCloseMenuIcon((current) => !current);
  };

  const handleOnSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchBoxRef?.current?.value) return;

    const newValue = searchBoxRef.current.value;
    const isSearchPage = router?.asPath?.split('?')?.[0] === '/search';
    searchBoxRef.current.value = '';
    searchBoxRef.current.blur();

    const sortQuery = router?.query.sort ? { sort: router.query.sort } : {};

    // INFO: Avoid pushing the same search query to the router if user is already on the search page
    if (newValue !== router.query.q || !isSearchPage) {
      router.push({ pathname: '/search', query: { q: newValue, ...sortQuery } });
    }
  };

  return (
    <header className={`${styles.header} site-header`} data-testid="header">
      <Link href="#nkn-body-section" tabIndex={0}>
        <a
          id="skipToBody"
          className={styles['skip-link']}
          onClick={() => document.getElementById('nkn-body-section')?.focus()}
        >
          Skip to Main Content
        </a>
      </Link>
      <Link href="#nkn-footer" tabIndex={0}>
        <a
          id="skipToFooter"
          className={styles['skip-link']}
          onClick={() => document.getElementById('nkn-footer')?.focus()}
        >
          Additional Site Navigation
        </a>
      </Link>

      {isShowAboveSearch && (
        <div className={`${styles.regionalText} ${styles.regionalTextMobile}`} data-testid="header-upper-div">
          {aboveSearch.region && <span>{aboveSearch.region}</span>}
          {aboveSearch.country && <span>{aboveSearch.country}</span>}
          {aboveSearch.continent && <span>{aboveSearch.continent}</span>}
        </div>
      )}

      <Navbar expand="lg" data-testid="header-navbar">
        <Navbar.Brand href={logoLink.href} title={logoLink.title} data-testid="header-navbar-brand">
          {logoPath && (
            <img
              src={logoPath.url}
              className={styles.logo}
              title={logoLink.title}
              width={68}
              height={68}
              alt={logoLink.title}
              loading="lazy"
              data-testid="header-logo-img"
            />
          )}
        </Navbar.Brand>

        <div className={styles.navIcons}>
          {Boolean(userBarBlock.length) && (
            <UserBarBlock data={userBarBlock} isDesktopIconNav={false} locale={locale} />
          )}

          <Navbar.Toggle
            onClick={handleOnToggleMenuIcon}
            data-testid="navbar-toggle"
            className={cx(styles.navbarToggle, {
              navbarToggleClose: showCloseMenuIcon,
            })}
          />
        </div>

        <Navbar.Collapse className={styles.navbarCollapse}>
          <div>
            <div
              className={`${styles.regionalText} ${styles.regionalTextDesktop}`}
              data-testid="header-upper-div-collapse"
            >
              {aboveSearch.region && <span>{aboveSearch.region}</span>}
              {aboveSearch.country && <span>{aboveSearch.country}</span>}
              {aboveSearch.continent && <span>{aboveSearch.continent}</span>}
            </div>

            {locale === appLocales.PT_BR && <div className={styles.noSearchSpacer} />}
            {locale !== appLocales.PT_BR && (
              <Form
                className={`d-flex ${styles.searchField}`}
                data-testid="header-search-form-collapse"
                onSubmit={handleOnSearchSubmit}
              >
                <button title="search" className={styles['search-icon']} type="submit">
                  <SearchIcon />
                </button>
                <Form.Control
                  type="search"
                  placeholder={formatCommonMessage({ id: 'search.placeholder', defaultMessage: 'Search' })}
                  aria-label="Search"
                  ref={searchBoxRef}
                />
              </Form>
            )}

            <div
              className={`${styles.regionalText} ${styles.regionalTextDesktop}`}
              data-testid="header-below-div-collapse"
            >
              {belowSearch.length &&
                belowSearch.map(({ linkText, linkUrl, openInNewWindow, uid }) => (
                  <span key={uid}>
                    <a
                      className={styles.localText}
                      href={linkUrl}
                      target={openInNewWindow ? '_blank' : '_self'}
                      rel="noreferrer"
                    >
                      <span>{linkText}</span>
                      <span className="sr-only"> (View in a new window)</span>
                    </a>
                  </span>
                ))}
            </div>
          </div>

          <div className={cx(styles.navContainer)}>
            <NavigationMenu
              menuItems={navigationMenu}
              isMobileDevice={isMobileDevice}
              ariaRole={'menubar'}
              ariaLabel={'main'}
            />

            {Boolean(userBarBlock.length) && (
              <UserBarBlock data={userBarBlock} isDesktopIconNav={true} locale={locale} />
            )}
          </div>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
